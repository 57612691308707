function addContactRow(form) {
    const newRow = form.querySelector('.contact-row').cloneNode(true)
    newRow.dataset.row = form.querySelector('.contacts').children.length
    form.querySelector('.contacts').insertAdjacentHTML('beforeend', newRow.outerHTML)
}

function removeContactRow(row) {
    row.remove()
}

document.addEventListener('click', (e) => {
    if (e.target.classList.contains('add-contact')) {
        const form = document.querySelector('.add-contact-form')
        addContactRow(form)
    }
    if (e.target.classList.contains('remove-row')) {
        const row = e.target.closest('.contact-row')
        if (row.dataset.row > 0) {
            removeContactRow(row)
        }
    }
})

function sendFormData(form) {
    const contactRows = form.querySelectorAll('.contact-row')
    const postId = form.querySelector('input[name="post_id"]').value
    const consent = document.querySelector('input[name="consent"]').checked
    const done = document.querySelector('input[name="done"]').checked

    const formData = new FormData(form)
    let valid = true

    let contactData = []
    contactRows.forEach((row) => {
        const rowData = {}
        const contact_ftgnr = row.querySelector('input[name="contact_ftgnr"]').value
        const name = row.querySelector('input[name="name"]').value
        const email = row.querySelector('input[name="email"]').value
        const phone = row.querySelector('input[name="phone"]').value
        const role = row.querySelector('select[name="role"]').value

        if (!name || !email || !phone || !role) {
            valid = false
        }
        rowData.contact_ftgnr = contact_ftgnr
        rowData.name = name
        rowData.email = email
        rowData.phone = phone
        rowData.role = role
        contactData.push(rowData)
    })

    if (!valid || !consent || !done) {
        form.querySelector('.error-message').classList.add('active')
        setTimeout(() => {
            form.querySelector('.error-message').classList.remove('active')
        }, 5000)
        return
    }

    formData.append('action', 'add_contact_row')
    formData.append('security', customerInfo.addContactRow)
    formData.append('post_id', postId)
    formData.append('contact_data', JSON.stringify(contactData))

    fetch(customerInfo.ajaxUrl, {
        method: 'POST',
        body: formData,
    })
        .then((response) => response.json())
        .then((data) => {
            console.log(data)
            if (data.success) {
                form.querySelector('.success-message').classList.add('active')
                const contacts = form.querySelector('.contacts')
                while (contacts.children.length > 1) {
                    contacts.lastElementChild.remove()
                }
                form.reset()
            } else {
                form.querySelector('.error-message').classList.add('active')
                setTimeout(() => {
                    form.querySelector('.error-message').classList.remove('active')
                }, 5000)
            }
        })
        .catch((error) => {
            console.error('Error:', error)
        })
}

document.addEventListener('click', (e) => {
    if (e.target.classList.contains('save-form')) {
        e.preventDefault()
        const form = document.querySelector('.add-contact-form')
        sendFormData(form)
    }
})

function checkFormConsent() {
    const consent = document.querySelector('input[name="consent"]').checked
    const done = document.querySelector('input[name="done"]').checked
    return consent && done
}

document.addEventListener('change', (e) => {
    if (e.target.id === 'consent' || e.target.id === 'done') {
        document.querySelector('.save-form').disabled = !checkFormConsent()
    }
})
